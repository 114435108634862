import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class CorretoraService {
    salvar(data) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/corretora/salvar`, data, config);
    }

    buscarPorCodigoCliente(codigoCliente) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/corretora/buscar-por/codigo-cliente/${ codigoCliente }`);
    }

    buscarPorCodigoCliente(codigoCliente) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/corretora/buscar-por/codigo-cliente/${ codigoCliente }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'nome,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/corretora/buscar-com-paginacao`, { params });
    }

    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/corretora/ativar/${ id }`);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/corretora/inativar/${ id }`);
    }
}

export default new CorretoraService();