import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class ContratoCompraVendaService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda`, dados, config);
    }

    buscarPorId(idContrato) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda/${ idContrato }`);
    }
    
    buscarPorContratoInterno(idContrato) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda/contrato-interno/${ idContrato }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda/buscar-com-paginacao`, { params });
    }

    buscarEstabelecimentoDeOrigem(idContrato) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda/estabelecimentos-origem/por/contrato-compra-venda/${ idContrato }`);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda/ativar/${ id }`);
    }

    tipoTributacao() {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/contratocompravenda/tipo-tributacao`);
    }
}

export default new ContratoCompraVendaService();