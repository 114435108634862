<template>
    <div class="flex">
        <span class="p-input-icon-right mr-1" style="width: 30%;">
            <i class="pi pi-search" @click="abrirFiltroAvancado()" v-if="!disabled" />
            <InputText 
                :disabled="disabled" 
                :class="{ 'p-invalid': invalid }"
                class="w-full"
                placeholder="Código"
                v-model="selecionado[codigo]"
                @keyup.enter="selecionar"
                @change="selecionar"
            />
        </span>

        <InputText 
            :disabled="true"
            :class="{ 'p-invalid': invalid }"
            style="width: 70%;"
            v-model="selecionado[descricao]"
            placeholder="Nome"
        />
    </div>

    <Dialog modal header="Buscar Corretora" v-model:visible="filtroAvancado"  :style="{width: '50%'}">

        <DataTable 
            @sort="sort($event)"
            showGridlines
            selectionMode="single"
            responsiveLayout="stack"
            :value="paginacao.content"
            v-model:selection="selecionadoNaListagem"
            @row-select="selecionar">

            <template #header>
                <div class="grid p-fluid formgrid align-items-center">
                    <div class="col-12 lg:col-2">
                        <div class="field">
                            <label for="codigo"><strong>Código</strong></label>
                            <InputText id="codigoCliente" class="w-full" v-model="filtros.codigoCliente" @keyup.enter="pesquisar" />
                        </div>
                    </div>

                    <div class="col-12 lg:col-4">
                        <div class="field">
                            <label for="nome"><strong>Nome</strong></label>
                            <InputText id="nome" class="w-full" v-model="filtros.nome" @keyup.enter="pesquisar" />
                        </div>
                    </div>

                    <div class="col-12 lg:col-2">
                        <div class="field">
                            <label for="cnpj"><strong>CNPJ</strong></label>
                            <InputText id="cnpj" class="w-full" v-model="filtros.cpfCnpj" @keyup.enter="pesquisar" />
                        </div>
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="pesquisar"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro"
                            ></Button>
                        </div>
                    </div>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="codigoCliente" header="Código" style="padding-top: 10px; padding-bottom: 10px;" sortable/>   
            <Column field="cpfCnpj" header="CPF/CNPJ" sortable/> 
            <Column field="nome" header="Nome" sortable/> 
        </DataTable>

        <Paginator
            :rows="filtros.size"
            :totalRecords="totalRegistro"
            :rowsPerPageOptions="[1, 5, 10]"
            @page="onPage"/>
    </Dialog>
</template>

<script>
export default {
    emits: ["pesquisar", "selecionado"],
    props: {
        selecionado: {
            type: Object,
            required: true
        },
        paginacao: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        invalid: {
            type: Boolean,
            required: false
        },
        codigo: {
            type: String,
            required: true
        },
        descricao: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            filtros: {},
            totalRegistro: 0,
            filtroAvancado: false,
            selecionadoNaListagem: null
        }
    },

    updated() {
        if(!!this.paginacao.pageable && !!this.paginacao.totalPages) {
            const qtdRegistros = this.paginacao.pageable.pageSize;
            this.totalRegistro = this.paginacao.totalPages * qtdRegistros;
        }
    },

    methods: {
        pesquisar() {
            this.$emit("pesquisar", this.filtros);
        },

        selecionar() {
            const codigo = !!this.selecionadoNaListagem ? this.selecionadoNaListagem[this.codigo] : this.selecionado[this.codigo];
            let registro = null;

            if(!!this.registro) {
                registro = Object.assign({}, this.registro);
            }
            
            this.$emit('selecionado', codigo, registro);
            this.fecharFiltroAvancado();
        },

        abrirFiltroAvancado() {
            this.filtros = {};
            this.filtros["size"] = 10;
            this.filtros["page"] = 0;
            this.filtros["sort"] = 'codigoCliente,asc';
            
            this.pesquisar();

            this.filtroAvancado = true;
        },

        fecharFiltroAvancado() {
            this.selecionadoNaListagem = null;
            this.filtroAvancado = false;
        },

        limparFiltro() {
            this.filtros["codigoCliente"] = undefined;
            this.filtros["cpfCnpj"] = undefined;
            this.filtros["nome"] = undefined;

            this.pesquisar();
        },

        onPage(event) {
            this.filtros["page"] = event.page + 1;
            this.filtros["size"] = event.rows;
            this.pesquisar();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.filtros["sort"] = `${ sortField },${sortOrder}`;
            this.pesquisar();
        }
    }
}
</script>